import * as React from "react"
import { useContext } from "react"
import CursorContext from "../../context/cursorContext"

// Components
import { Section, Container } from "../StyledComponents/layoutComponentStyles"

// Animations
import { pageTransitionInstant } from "../../animations/globalVariants"
import { $desktop, $tablet, $mobile } from "../../utils/breakpoints"

/*









*/

const TermsPolicy: React.FunctionComponent = () => {
  const { setCursorState } = useContext(CursorContext)
  return (
    <Section spaced initial='initial' animate='animate' exit='exit' variants={pageTransitionInstant} id={"terms"}>
      <Container tenClm={$desktop ? true : false} twelveClm={$tablet ? true : false} fourteenClm={$mobile ? true : false}>
        <h1>Terms</h1>
        <p className='o-par-sml' style={{ marginTop: "1rem", opacity: 0.5 }}>
          <i>Last updated November 26th, 2020</i>
        </p>

        <div className='o-term-container'>
          <h4>Agreement to Terms</h4>
          <p>
            These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity
            <i> (“you”)</i> and Nathan Hughes, doing business as Silk Studio <i>("Silk Studio", “we”, “us”, or “our”)</i>, concerning
            your access to and use of the <i>https://www.silkstudio.co</i> website as well as any other media form, media channel,
            mobile website or mobile application related, linked, or otherwise connected thereto <i>(collectively, the “Site”)</i>. You
            agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use.
            <br />
            <br />
            IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.
            <br />
            <br />
            Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly
            incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these
            Terms of Use at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of
            these Terms of Use, and you waive any right to receive specific notice of each such change. It is your responsibility to
            periodically review these Terms of Use to stay informed of updates. You will be subject to, and will be deemed to have been
            made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the
            date such revised Terms of Use are posted.
            <br />
            <br />
            The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law or regulation or which would subject us to any
            registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from
            other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the
            extent local laws are applicable.
            <br />
            <br />
            The Site is intended for users who are at least 13 years of age. All users who are minors in the jurisdiction in which they
            reside <i>(generally under the age of 18)</i> must have the permission of, and be directly supervised by, their parent or
            guardian to use the Site. If you are a minor, you must have your parent or guardian read and agree to these Terms of Use
            prior to you using the Site.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Intellectual Property Right</h4>
          <p>
            Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software,
            website designs, audio, video, text, photographs, and graphics on the Site <i>(collectively, the “Content”)</i> and the
            trademarks, service marks, and logos contained therein<i> (the “Marks”)</i> are owned or controlled by us or licensed to
            us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition
            laws of the United States, international copyright laws, and international conventions. The Content and the Marks are
            provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms of
            Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted,
            publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any
            commercial purpose whatsoever, without our express prior written permission.
            <br />
            <br />
            Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to
            download or print a copy of any portion of the Content to which you have properly gained access solely for your personal,
            non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>User Representations</h4>
          <p>By using the Site, you represent and warrant that:</p>
          <ol>
            <li>you have the legal capacity and you agree to comply with these Terms of Use; </li>
            <li>you are not under the age of 13; </li>
            <li>
              you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use
              the Site;
            </li>
            <li>you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise;</li>
            <li>you will not use the Site for any illegal or unauthorized purpose; and</li>
            <li>your use of the Site will not violate any applicable law or regulation.</li>
          </ol>
          <p>
            If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or
            terminate your account and refuse any and all current or future use of the Site <i>(or any portion thereof)</i>.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Prohibited Activities</h4>
          <p>
            You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not
            be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us. As a
            user of the Site, you agree not to:
          </p>
          <ol>
            <li>
              Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission from us.
            </li>
            <li>Trick, defraud, or mislead us and other users,</li>
            <li>
              Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent
              or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained
              therein.
            </li>
            <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
            <li>Use any information obtained from the Site in order to harass, abuse, or harm another person.</li>
            <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
            <li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
            <li>
              Upload or transmit <i>(or attempt to upload or to transmit) </i>viruses, Trojan horses, or other material, including
              excessive use of capital letters and spamming <i>(continuous posting of repetitive text)</i>, that interferes with any
              party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use,
              features, functions, operation, or maintenance of the Site.
            </li>
            <li>Delete the copyright or other proprietary rights notice from any Content.</li>
            <li>Attempt to impersonate another user or person or use the username of another user.</li>
            <li>Sell or otherwise transfer your profile.</li>
            <li>Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.</li>
            <li>
              Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to
              you.
            </li>
            <li>
              Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the
              Site.
            </li>
            <li>Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
            <li>
              Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of
              the Site.
            </li>
            <li>
              Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any
              automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that
              accesses the Site, or using or launching any unauthorized script or other software.
            </li>
            <li>Use a buying agent or purchasing agent to make purchases on the Site.</li>
            <li>
              Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or
              other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false
              pretenses.
            </li>
            <li>
              Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any
              revenue-generating endeavor or commercial enterprise.
            </li>
          </ol>
        </div>
        <div className='o-term-container'>
          <h4>User Generated Contributions</h4>
          <p>
            The Site does not offer users to submit or post content. We may provide you with the opportunity to create, submit, post,
            display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not
            limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other
            material <i>(collectively, "Contributions")</i>. Contributions may be viewable by other users of the Site and through
            third-party websites. As such, any Contributions you transmit may be treated in accordance with the Site Privacy Policy.
            When you create or make available any Contributions, you thereby represent and warrant that:
          </p>
          <ol>
            <li>
              The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of
              your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright,
              patent, trademark, trade secret, or moral rights of any third party.
            </li>
            <li>
              You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and
              to authorize us, the Site, and other users of the Site to use your Contributions in any manner contemplated by the Site
              and these Terms of Use.
            </li>
            <li>
              You have the written consent, release, and/or permission of each and every identifiable individual person in your
              Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and
              use of your Contributions in any manner contemplated by the Site and these Terms of Use.
            </li>
            <li>Your Contributions are not false, inaccurate, or misleading.</li>
            <li>
              Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain
              letters, spam, mass mailings, or other forms of solicitation.
            </li>
            <li>
              Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise
              objectionable <i>(as determined by us)</i>.
            </li>
            <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
            <li>
              Your Contributions are not used to harass or threaten <i>(in the legal sense of those terms)</i> any other person and to
              promote violence against a specific person or class of people.
            </li>
            <li>Your Contributions do not violate any applicable law, regulation, or rule.</li>
            <li>Your Contributions do not violate the privacy or publicity rights of any third party. </li>
            <li>
              Your Contributions do not contain any material that solicits personal information from anyone under the age of 18 or
              exploits people under the age of 18 in a sexual or violent manner.
            </li>
            <li>
              Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the
              health or well-being of minors.
            </li>
            <li>
              Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual
              preference, or physical handicap.
            </li>
            <li>
              Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or
              any applicable law or regulation.
            </li>
          </ol>
          <p>
            Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among other things,
            termination or suspension of your rights to use the Site.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Contribution License</h4>
          <p>
            You and the Site agree that we may access, store, process, and use any information and personal data that you provide
            following the terms of the Privacy Policy and your choices <i>(including settings)</i>. By submitting suggestions or other
            feedback regarding the Site, you agree that we can use and share such feedback for any purpose without compensation to you.
            We do not assert any ownership over your Contributions.
            <br />
            <br />
            You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights
            associated with your Contributions. We are not liable for any statements or representations in your Contributions provided
            by you in any area on the Site. You are solely responsible for your Contributions to the Site and you expressly agree to
            exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Submissions</h4>
          <p>
            You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the
            Site <i>("Submissions")</i> provided by you to us are non-confidential and shall become our sole property.
            <br />
            <br />
            We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use
            and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such
            Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no
            recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your
            Submissions.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Third-Party Website and Content</h4>
          <p>
            The Site may contain <i>(or you may be sent via the Site)</i> links to other websites <i>("Third-Party Websites")</i> as
            well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software,
            and other content or items belonging to or originating from third parties <i>("Third-Party Content")</i>. Such Third-Party
            Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness
            by us, and we are not responsible for any Third-Party Websites accessed through the Site or any Third-Party Content posted
            on, available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability,
            privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.
            <br />
            <br />
            Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does
            not imply approval or endorsement thereof by us. If you decide to leave the Site and access the Third-Party Websites or to
            use or install any Third-Party Content, you do so at your own risk, and you should be aware these Terms of Use no longer
            govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any website
            to which you navigate from the Site or relating to any applications you use or install from the Site.
            <br />
            <br />
            Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no
            responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party.
            You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall
            hold us harmless from any harm caused by your purchase of such products or services. Additionally, you shall hold us
            harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party
            Content or any contact with Third-Party Websites.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Site Management</h4>
          <p>We reserve the right, but not the obligation, to:</p>
          <ol>
            <li>Monitor the Site for violations of these Terms of Use</li>
            <li>
              Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use,
              including without limitation, reporting such user to law enforcement authorities
            </li>
            <li>
              In our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the
              extent technologically feasible) any of your Contributions or any portion thereof
            </li>
            <li>
              In our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all
              files and content that are excessive in size or are in any way burdensome to our systems
            </li>
            <li>
              Otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper
              functioning of the Site.
            </li>
          </ol>
        </div>
        <div className='o-term-container'>
          <h4>Privacy Policy</h4>
          <p>
            We care about data privacy and security. By using the Site, you agree to be bound by our Privacy Policy posted on the Site,
            which is incorporated into these Terms of Use. Please be advised the Site is hosted in the United States. If you access the
            Site from any other region of the world with laws or other requirements governing personal data collection, use, or
            disclosure that differ from applicable laws in the United States, then through your continued use of the Site, you are
            transferring your data to the United States, and you agree to have your data transferred to and processed in the United
            States.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Term and Termination</h4>
          <p>
            These Terms of Use shall remain in full force and effect while you use the Site.
            <br />
            <br />
            WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
            NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE <i>(INCLUDING BLOCKING CERTAIN IP ADDRESSES)</i>, TO ANY PERSON FOR
            ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED
            IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR
            DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
            <br />
            <br />
            If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account
            under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third
            party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action,
            including without limitation pursuing civil, criminal, and injunctive redress.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Modifications and Interuptions</h4>
          <p>
            We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole
            discretion without notice. However, we have no obligation to update any information on our Site.
            <br />
            <br />
            We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be
            liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site. We cannot
            guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to
            perform maintenance related to the Site, resulting in interruptions, delays, or errors.
            <br />
            <br />
            We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any
            reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused
            by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of
            Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in
            connection therewith.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Governing Law</h4>
          <p>
            These conditions are governed by and interpreted following the laws of the United Kingdom, and the use of the United
            Nations Convention of Contracts for the International Sale of Goods is expressly excluded. If your habitual residence is in
            the EU, and you are a consumer, you additionally possess the protection provided to you by obligatory provisions of the law
            of your country of residence. Nathan Hughes and yourself both agree to submit to the non-exclusive jurisdiction of the
            courts of England and Wales, which means that you may make a claim to defend your consumer protection rights in regards to
            these Conditions of Use in the United Kingdom, or in the EU country in which you reside.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Dispute Resolution</h4>
          <p>
            The European Commission provides an online dispute resolution platform, which you can access{" "}
            <a
              href='https://ec.europa.eu/consumers/odr'
              className='o-inline-link is-bodylink'
              onMouseEnter={() => setCursorState?.("is-link")}
              onMouseLeave={() => setCursorState?.("")}
            >
              here
            </a>
            . If you would like to bring this subject to our attention, please contact us.
          </p>
        </div>
        <div className='o-term-container'>
          {" "}
          <h4>Corrections</h4>
          <p>
            There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including
            descriptions, pricing, availability, and various other information. We reserve the right to correct any errors,
            inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Disclaimer</h4>
          <p>
            The site is provided on an as-is and as-available basis. You agree that your use of the site and our services will be at
            your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or implied, in connection with
            the site and your use thereof, including, without limitation, the implied warranties of merchantability, fitness for a
            particular purpose, and non-infringement.
            <br />
            <br />
            We make no warranties or representations about the accuracy or completeness of the site’s content or the content of any
            websites linked to the site and we will assume no liability or responsibility for any (1) errors, mistakes, or inaccuracies
            of content and materials, (2) personal injury or property damage, of any nature whatsoever, resulting from your access to
            and use of the site, (3) any unauthorized access to or use of our secure servers and/or any and all personal information
            and/or financial information stored therein, (4) any interruption or cessation of transmission to or from the site, (5) any
            bugs, viruses, trojan horses, or the like which may be transmitted to or through the site by any third party, and/or (6)
            any errors or omissions in any content and materials or for any loss or damage of any kind incurred as a result of the use
            of any content posted, transmitted, or otherwise made available via the site.
            <br />
            <br />
            We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third
            party through the site, any hyperlinked website, or any website or mobile application featured in any banner or other
            advertising, and we will not be a party to or in any way be responsible for monitoring any transaction between you and any
            third-party providers of products or services. As with the purchase of a product or service through any medium or in any
            environment, you should use your best judgment and exercise caution where appropriate.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Limitaions and Liability</h4>
          <p>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
            CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
            OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Indemnification</h4>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective
            officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including
            reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:
          </p>
          <ol>
            <li>Use of the Site</li>
            <li>Breach of these Terms of Use</li>
            <li>Any breach of your representations and warranties set forth in these Terms of Use</li>
            <li>Your violation of the rights of a third party, including but not limited to intellectual property rights</li>
            <li>Any overt harmful act toward any other user of the Site with whom you connected via the Site</li>
          </ol>
          <p>
            Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any
            matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such
            claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this
            indemnification upon becoming aware of it.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>User Data</h4>
          <p>
            We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as
            well as data relating to your use of the Site. Although we perform regular routine backups of data, you are solely
            responsible for all data that you transmit or that relates to any activity you have undertaken using the Site. You agree
            that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of
            action against us arising from any such loss or corruption of such data.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Electronic Communications, Transactions and Signatures</h4>
          <p>
            Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to
            receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we
            provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in
            writing. <br />
            <br /> YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
            DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. <br />
            <br /> You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the
            granting of credits by any means other than electronic means.
          </p>
        </div>
        <div className='o-term-container'>
          <h4>Miscellaneous</h4>
          <p>
            These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site constitute the
            entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these
            Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent
            permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be
            responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.
            <br />
            <br />
            If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and
            enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship
            created between you and us as a result of these Terms of Use or use of the Site.
            <br />
            <br />
            You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any
            and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties
            hereto to execute these Terms of Use.
          </p>
        </div>
      </Container>
    </Section>
  )
}

export default TermsPolicy
