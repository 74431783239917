import * as React from "react"
import { useEffect, useContext } from "react"
import { useLocation } from "@reach/router"
import ReactGA from "react-ga"

// Styles
import "../components/Terms/_terms.scss"

// Components
import SEO from "../components/seo"

// Animations
import { ScrollTrigger } from "gsap/ScrollTrigger"
import ScrollSafeContext from "../context/scrollSafeContext"
import TermsPolicy from "../components/Terms/TermsPolicy"
import PrivacyPolicy from "../components/Terms/PrivacyPolicy"
import ContactPolicy from "../components/Terms/ContactPolicy"

// SEO
const seo = {
  title: "Terms",
  description: "By using our website ou agree to both our terms and conditions and our privacy policy.",
}

/*









*/

const TermsPage: React.FunctionComponent = () => {
  // Context
  const { scrollSafe, setScrollSafe } = useContext(ScrollSafeContext)
  const location = useLocation()

  // Effects
  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh()
      setScrollSafe?.(true)
    }, 600)

    ReactGA.pageview(window.location.pathname + window.location.search)

    return () => {
      setScrollSafe?.(false)
    }
  }, [])

  useEffect(() => {
    if (scrollSafe && location.hash === "#privacy") {
      const scroll = document.querySelector("#terms")?.scrollHeight

      setTimeout(() => {
        if (scroll) {
          window.scrollTo(0, scroll)
        }
      }, 1500)
    }
  }, [scrollSafe])

  return (
    <>
      <SEO title={seo.title} description={seo.description} />
      {scrollSafe && (
        <>
          <TermsPolicy />
          <PrivacyPolicy />
          <ContactPolicy />
        </>
      )}
    </>
  )
}

export default TermsPage
