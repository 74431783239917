import * as React from "react"
import { useContext } from "react"
import CursorContext from "../../context/cursorContext"

// Components
import { Section, Container } from "../StyledComponents/layoutComponentStyles"

// Animations
import { pageTransitionInstant } from "../../animations/globalVariants"
import { $desktop, $mobile, $tablet } from "../../utils/breakpoints"

/*









*/

const PrivacyPolicy: React.FunctionComponent = () => {
  const { setCursorState } = useContext(CursorContext)
  return (
    <Section spaced initial='initial' animate='animate' exit='exit' variants={pageTransitionInstant} id={"privacy"}>
      <Container tenClm={$desktop ? true : false} twelveClm={$tablet ? true : false} fourteenClm={$mobile ? true : false}>
        <h1>Privacy</h1>
        <p className='o-par-sml' style={{ marginTop: "1rem", opacity: 0.5 }}>
          <i>Last updated November 26th, 2020</i>
        </p>
        <div className='o-term-container'>
          <p>
            Thank you for choosing to be part of our community at Nathan Hughes, doing business as Silk Studio{" "}
            <i>("Silk Studio", "we", "us", "our")</i>. We are committed to protecting your personal information and your right to
            privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal
            information, please contact us at hello@silkstudio.co.
            <br />
            <br />
            When you visit our website <i>http://www.silkstudio.co</i>, and use our services, you trust us with your personal
            information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to
            explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation
            to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy
            policy that you do not agree with, please discontinue use of our Sites and our services.
            <br />
            <br />
            This privacy policy applies to all information collected through our website <i>(such as http://www.silkstudio.co)</i>,
            and/or any related services, sales, marketing or events{" "}
            <i>(we refer to them collectively in this privacy policy as the "Sites")</i>.
            <br />
            <br />
            Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal
            information with us.
          </p>
        </div>

        <div className='o-term-container'>
          <h4>1. What information do we collect?</h4>
          <div className='o-term-container__sub-term'>
            <h6>Personal information you disclose to us</h6>
            <p>
              <b>In Short:</b> We collect personal information that you provide to us such as name, address, contact information,
              passwords and security data, and payment information.
              <br />
              <br />
              We collect personal information that you voluntarily provide to us when expressing an interest in obtaining information
              about us or our products and services, when participating in activities on the Sites or otherwise contacting us.
              <br />
              <br />
              The personal information that we collect depends on the context of your interactions with us and the Sites, the choices
              you make and the products and features you use. The personal information we collect may include the following:
              <br />
              <br />
              <b>Name and Contact Data.</b> We collect your first and last name, email address, postal address, phone number, and other
              similar contact data.
              <br />
              <br />
              <b>Credentials.</b> We collect passwords, password hints, and similar security information used for authentication and
              account access.
              <br />
              <br />
              <b>Payment Data.</b> We collect data necessary to process your payment if you make purchases, such as your payment
              instrument number <i>(such as a credit card number)</i>, and the security code associated with your payment instrument.
              All payment data is stored by our payment processor and you should review its privacy policies and contact the payment
              processor directly to respond to your questions.
              <br />
              <br />
              All personal information that you provide to us must be true, complete and accurate, and you must notify us of any
              changes to such personal information.
            </p>
          </div>

          <div className='o-term-container__sub-term'>
            <h6>Information automatically collected</h6>
            <p>
              <b>In Short:</b> Some information — such as IP address and/or browser and device characteristics — is collected
              automatically when you visit our Sites. We automatically collect certain information when you visit, use or navigate the
              Sites.
              <br />
              <br />
              This information does not reveal your specific identity <i>(like your name or contact information)</i> but may include
              device and usage information, such as your IP address, browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location, information about how and when you use our Sites and other
              technical information.
              <br />
              <br />
              This information is primarily needed to maintain the security and operation of our Sites, and for our internal analytics
              and reporting purposes.
            </p>
          </div>

          <div className='o-term-container__sub-term'>
            <h6>Information collected from other sources</h6>
            <p>
              <b>In Short: </b> We may collect limited data from public databases, marketing partners, and other outside sources.
              <br />
              <br />
              We may obtain information about you from other sources, such as public databases, joint marketing partners, as well as
              from other third parties. Examples of the information we receive from other sources include: social media profile
              information; marketing leads and search results and links, including paid listings <i>(such as sponsored links)</i>.
            </p>
          </div>
        </div>
        <div className='o-term-container'>
          <h4>2. How do we use your information?</h4>
          <p>
            <b>In Short:</b> We process your information for purposes based on legitimate business interests, the fulfillment of our
            contract with you, compliance with our legal obligations, and/or your consent.
            <br />
            <br />
            We use personal information collected via our Sites for a variety of business purposes described below. We process your
            personal information for these purposes in reliance on our legitimate business interests <i>("Business Purposes")</i>, in
            order to enter into or perform a contract with you <i>("Contractual")</i>, with your consent <i>("Consent")</i>, and/or for
            compliance with our legal obligations <i>("Legal Reasons")</i>. We indicate the specific processing grounds we rely on next
            to each purpose listed below.
            <br />
            <br />
            We use the information we collect or receive:
          </p>
          <ul>
            <li>
              <b>To send you marketing and promotional communications.</b> We and/or our third party marketing partners may use the
              personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences.
              You can opt-out of our marketing emails at any time <i>(see the "What are your privacy rights" section below)</i>.
            </li>
            <li>
              <b>Deliver targeted advertising to you.</b> We may use your information to develop and display content and advertising
              <i>(and work with third parties who do so)</i> tailored to your interests and/or location and to measure its
              effectiveness.
            </li>
            <li>
              <b>To protect our Sites.</b> We may use your information as part of our efforts to keep our Sites safe and secure{" "}
              <i>(for example, for fraud monitoring and prevention)</i>.
            </li>
            <li>
              <b>To enforce our terms, conditions and policies.</b>
            </li>
            <li>
              <b>To respond to legal requests and prevent harm.</b> If we receive a subpoena or other legal request, we may need to
              inspect the data we hold to determine how to respond.
            </li>
            <li>
              <b>For other Business Purposes.</b> We may use your information for other Business Purposes, such as data analysis,
              identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our
              Sites, products, services, marketing and your experience.
            </li>
          </ul>
        </div>
        <div className='o-term-container'>
          <h4>3. Will your information be shared with anyone?</h4>
          <p>
            <b>In Short:</b> We only share information with your consent, to comply with laws, to provide you with services, to protect
            your rights, or to fulfill business obligations.
            <br />
            <br />
            We may process or share your data that we hold based on the following legal basis:
          </p>
          <ul>
            <li>
              <b>Consent:</b> We may process your data if you have given us specific consent to use your personal information for a
              specific purpose.
            </li>
            <li>
              <b>Legitimate Interests:</b> We may process your data when it is reasonably necessary to achieve our legitimate business
              interests.
            </li>

            <li>
              <b>Performance of a Contract:</b> Where we have entered into a contract with you, we may process your personal
              information to fulfill the terms of our contract.
            </li>

            <li>
              <b>Legal Obligations:</b> We may disclose your information where we are legally required to do so in order to comply with
              applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a
              court order or a subpoena{" "}
              <i>(including in response to public authorities to meet national security or law enforcement requirements)</i>.
            </li>
            <li>
              <b>Vital Interests:</b> We may disclose your information where we believe it is necessary to investigate, prevent, or
              take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to
              the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
            </li>
          </ul>
          <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>
          <ul>
            <li>
              <b>Business Transfers. </b>We may share or transfer your information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
            </li>
            <li>
              <b>Vendors, Consultants and Other Third-Party Service Providers.</b> We may share your data with third party vendors,
              service providers, contractors or agents who perform services for us or on our behalf and require access to such
              information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services,
              customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Sites,
              which will enable them to collect data about how you interact with the Sites over time. This information may be used to,
              among other things, analyze and track data, determine the popularity of certain content and better understand online
              activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third
              parties for their promotional purposes.
            </li>
          </ul>
        </div>

        <div className='o-term-container'>
          <h4>4. Who will your information be shared with?</h4>
          <p>
            <b>In Short:</b> We only share information with the following third parties.
            <br />
            <br />
            We only share and disclose your information with the following third parties. We have categorised each party so that you
            may be easily understand the purpose of our data collection and processing practices. If we have processed your data based
            on your consent and you wish to revoke your consent, please contact us.
          </p>
          <ul>
            <li>
              <b>Content Optimisation:</b> YouTube video embed, Vimeo video and MailChimp widget plugin
            </li>
            <li>
              <b>Retargeting Platforms:</b> Facebook Remarketing
            </li>
          </ul>
        </div>

        <div className='o-term-container'>
          <h4>5. How long do we keep your information?</h4>
          <p>
            <b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy
            unless otherwise required by law.
            <br />
            <br />
            We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy,
            unless a longer retention period is required or permitted by law{" "}
            <i>(such as tax, accounting or other legal requirements)</i>. No purpose in this policy will require us keeping your
            personal information for longer than 2 years.
            <br />
            <br />
            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize
            it, or, if this is not possible <i>(for example, because your personal information has been stored in backup archives)</i>,
            then we will securely store your personal information and isolate it from any further processing until deletion is
            possible.
          </p>
        </div>

        <div className='o-term-container'>
          <h4>6. How do we keep your information safe?</h4>
          <p>
            <b>In Short:</b> We aim to protect your personal information through a system of organisational and technical security
            measures.
            <br />
            <br />
            We have implemented appropriate technical and organisational security measures designed to protect the security of any
            personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100%
            secure. Although we will do our best to protect your personal information, transmission of personal information to and from
            our Sites is at your own risk. You should only access the services within a secure environment.
          </p>
        </div>

        <div className='o-term-container'>
          <h4>7. Do we collect information from minors?</h4>
          <p>
            <b>In Short: </b> We do not knowingly collect data from or market to children under 18 years of age.
            <br />
            <br />
            We do not knowingly solicit data from or market to children under 18 years of age. By using the Sites, you represent that
            you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the
            Sites. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate
            the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we
            may have collected from children under age 18, please contact us at hello@silkstudio.co.
          </p>
        </div>

        <div className='o-term-container'>
          <h4>8. What are your privacy rights?</h4>
          <p>
            <b>In Short:</b> In some regions, such as the European Economic Area, you have rights that allow you greater access to and
            control over your personal information. You may review, change, or terminate your account at any time.
            <br />
            <br />
            In some regions <i>(like the European Economic Area)</i>, you have certain rights under applicable data protection laws.
            These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request
            rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data
            portability. In certain circumstances, you may also have the right to object to the processing of your personal
            information. To make such a request, please use the contact details provided below. We will consider and act upon any
            request in accordance with applicable data protection laws.
            <br />
            If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any
            time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.
            <br />
            <br />
            If you are a resident in the European Economic Area and you believe we are unlawfully processing your personal information,
            you also have the right to complain to your local data protection supervisory authority. You can find their contact
            details&nbsp;
            <a
              href='http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html'
              className='o-inline-link is-bodylink'
              onMouseEnter={() => setCursorState?.("is-link")}
              onMouseLeave={() => setCursorState?.("")}
            >
              here
            </a>
            .
          </p>
        </div>

        <div className='o-term-container'>
          <h4>9. Controls for do-not-track features</h4>
          <p>
            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track <i>("DNT")</i> feature
            or setting you can activate to signal your privacy preference not to have data about your online browsing activities
            monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As
            such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice
            not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform
            you about that practice in a revised version of this Privacy Policy.
          </p>
        </div>

        <div className='o-term-container'>
          <h4>10. Do California residents have specific privacy rights?</h4>
          <p>
            <b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding access to your
            personal information.
            <br />
            <br />
            California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California
            residents to request and obtain from us, once a year and free of charge, information about categories of personal
            information <i>(if any)</i> we disclosed to third parties for direct marketing purposes and the names and addresses of all
            third parties with which we shared personal information in the immediately preceding calendar year. If you are a California
            resident and would like to make such a request, please submit your request in writing to us using the contact information
            provided below.
            <br />
            <br />
            If you are under 18 years of age, reside in California, and have a registered account with the Sites, you have the right to
            request removal of unwanted data that you publicly post on the Sites. To request removal of such data, please contact us
            using the contact information provided below, and include the email address associated with your account and a statement
            that you reside in California. We will make sure the data is not publicly displayed on the Sites, but please be aware that
            the data may not be completely or comprehensively removed from all our systems.
          </p>
        </div>

        <div className='o-term-container'>
          <h4>11. Do we make updates to this policy?</h4>
          <p>
            <b>In Short:</b> Yes, we will update this policy as necessary to stay compliant with relevant laws. We may update this
            privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated
            version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify
            you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to
            review this privacy policy frequently to be informed of how we are protecting your information.
          </p>
        </div>

        <div className='o-term-container'>
          <h4>12. How can you review, update, or delete the data we collect from you?</h4>
          <p>
            Based on the applicable laws of your country, you may have the right to request access to the personal information we
            collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your
            personal information, please contact us using the details below. We will respond to your request within 30 days.
          </p>
        </div>
      </Container>
    </Section>
  )
}

export default PrivacyPolicy
