import * as React from "react"
import { useContext } from "react"
import CursorContext from "../../context/cursorContext"

// Components
import { Section, Container } from "../StyledComponents/layoutComponentStyles"

// Animations
import { pageTransitionInstant } from "../../animations/globalVariants"
import { $desktop, $mobile, $tablet } from "../../utils/breakpoints"

/*









*/

const ContactPolicy: React.FunctionComponent = () => {
  const { setCursorState } = useContext(CursorContext)
  return (
    <Section spaced initial='initial' animate='animate' exit='exit' variants={pageTransitionInstant}>
      <Container tenClm={$desktop ? true : false} twelveClm={$tablet ? true : false} fourteenClm={$mobile ? true : false}>
        <h1 style={{ marginBottom: "1.25rem" }}>Contact</h1>
        <p>
          In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please write
          to us at either:
          <br />
          <br />
          <a
            href='mailto:hello@silkstudio.co'
            className='o-inline-link is-bodylink'
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
          >
            hello@silkstudio.co
          </a>
          <br />
          <br />
          or
          <br />
          <br />
          FAO: Silk Studio
          <br />
          The Old Stables
          <br />
          Shrewsbury
          <br />
          SY3 9HQ
          <br />
          United Kingdom
        </p>
      </Container>
    </Section>
  )
}

export default ContactPolicy
